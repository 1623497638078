<template>
  <tr>
    <td></td>
    <!-- <td v-if="item.robotId">{{ item.robotId }}</td>
    <td v-if="item.multiplePosId">{{ item.multiplePosId }}</td>-->
    <td>{{ lat }}</td>
    <td>{{ lon }}</td>
  </tr>
</template>

<script>
export default {
  props: ["lat", "lon"],
  data() {
    return {};
  },
  methods: {}
};
</script>