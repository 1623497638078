<template>
  <div>
    <ros-status :ros="this.ros" @rosConnected="rosConnected" @rosUnConnected="rosUnConnected"></ros-status>
    <ros ref="rosObject" :url="this.rosIp" @getRosObject="setRos"></ros>
    <b-card>
      <robot-form v-on:getRobot="setRosIp"></robot-form>
      <b-button @click="reconnection" variant="primary">再接続</b-button>
    </b-card>
    <b-card>
      <label>畑名</label>
      <field-form v-on:getField="setField"></field-form>
    </b-card>
    <b-card>
      <google-map
        ref="gmapObject"
        :lat="lat"
        :lng="lon"
        :degree="degree"
        :markers="markers"
        :propMapPos="{lat:this.field.lat,lng:this.field.lon}"
      ></google-map>
      <b-row>
        <b-col cols="12" lg="6">
          <strong>目標までの距離（ｍ）</strong>
        </b-col>
        <b-col cols="12" lg="6">{{distance}}</b-col>
      </b-row>
      <b-row>
        <b-col cols="4" lg="4">
          <strong>現在地</strong>
        </b-col>
        <b-col cols="4" lg="4">{{lat}}</b-col>
        <b-col cols="4" lg="4">{{lon}}</b-col>
      </b-row>
    </b-card>
    <multiple-run-action
      @multipleStop="operationHandlar"
      @multipleRun="operationHandlar"
      @multipleReturn="operationHandlar"
      v-bind:targetPointNum="targetPointNum"
    ></multiple-run-action>
    <multiple-position-calc @calcResult="multiplePositionHandlar" v-bind:lat="lat" v-bind:lon="lon"></multiple-position-calc>
    <b-card>
      <b-table responsive="sm" :items="existsPositions" :fields="fields">
        <template v-slot:cell(index)="data">
          <td>{{data.index + 1}}</td>
        </template>
        <template v-slot:cell(lat)="data">
          <td>{{data.item.lat}}</td>
        </template>
        <template v-slot:cell(lon)="data">
          <td>{{data.item.lon}}</td>
        </template>
        <template v-slot:cell(wayType)="data">
          <td>{{data.item.wayType}}</td>
        </template>
      </b-table>
      <div slot="footer">
        <b-row>
          <b-col>
            <div>
              <b-button size="sm" variant="danger" @click="deleteAllPosition">
                <i class="fa fa-minus-circle"></i> 登録地点をすべて削除
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import ROSLIB from "roslib";
import Ros from "../components/Ros";
import RobotForm from "../components/form/RobotForm";
import RosStatus from "../components/RosStatus";
import GoogleMap from "../components/GoogleMap";
import MultipleRunAction from "../components/MultipleRunAction";
import MultiplePositionCalc from "../components/MultiplePositionCalc";
import MultiplePositionTable from "../components/MultiplePositionTable";
import FieldForm from "../components/form/FieldForm";
import { typeIncompatibleAnonSpreadMessage } from "graphql/validation/rules/PossibleFragmentSpreads";
import { MULTIPLE_POS_QUERY } from "../../components/multiple-pos-query";
import {
  UPDATE_MULTIPLE_POS,
  DELETE_MULTIPLE_POS
} from "../../components/multiple-pos-mutation";

const ROW_START = 1;
const ROW_END = 2;
const STATION_START = 3;
const STATION_END = 4;

export default {
  components: {
    Ros,
    GoogleMap,
    MultipleRunAction,
    MultiplePositionCalc,
    MultiplePositionTable,
    RobotForm,
    RosStatus,
    FieldForm
  },
  data() {
    return {
      robotId: null,
      lat: null,
      lon: null,
      degree: 0,
      onTheWayFlag: null,
      distance: 0,
      targetPointNum: 1,
      existsPositions: [],
      monitoringTopic: null,
      saveMultiplePositionsService: null,
      saveMultiplePositionsSetService: null,
      findPosService: null,
      deletePositionsService: null,
      runClient: null,
      fields: [
        { key: "index", label: "index" },
        { key: "lat", label: "緯度" },
        { key: "lon", label: "経度" },
        { key: "wayType", label: "経路種別" }
      ],
      rosIp: null,
      ros: null,
      field: {},
      multiplePos: [],
      stationMultiplePos: [],
      markers: []
    };
  },
  methods: {
    setField(value) {
      this.field = value;

      if (this.field) {
        this.$apollo.addSmartQuery("multiplePos", {
          query: MULTIPLE_POS_QUERY,
          variables: {
            fieldId: this.field.id
          },
          result({ data, load }) {
            if (data) {
              this.setExistsPositions(this.multiplePos.edges);
            }
          }
        });
      }
    },
    setRosIp(value) {
      this.rosIp = value.ip;
    },
    setRos(value) {
      this.ros = value;
    },
    rosConnected() {
      this.setPos();
      this.setDegree();
      this.setDistance();
      // this.setExistsPositions();
    },
    rosUnConnected() {
      // 初期化
      // マップのロボットマーカーを非表示
      this.lat = null;
      this.lon = null;
      this.degree = 0;
    },
    reconnection() {
      this.existsPositions = [];
      // this.$refs.gmapObject.deleteMarker();
      this.markers = [];
      this.$refs.rosObject.init();
    },
    setPos: function() {
      let _this = this;
      this.posTopic = this.$refs.rosObject.createTopicWithMsgType(
        "/legmin_himawari_magellan_rtk/rtk",
        "/legmin_himawari_magellan_rtk/rtk_pos"
      );
      this.posTopic.subscribe(function(message) {
        _this.lat = message.lat;
        _this.lon = message.lon;
      });
    },
    setDegree: function() {
      let _this = this;
      this.degreeTopic = this.$refs.rosObject.createTopic(
        "/legmin_himawari_mi/magnetism_azimuth"
      );
      this.degreeTopic.subscribe(function(message) {
        _this.degree = message.data;
      });
    },
    setDistance: function() {
      let _this = this;
      this.distTopic = this.$refs.rosObject.createTopic(
        "/legmin_himawari_navigation/target_distance"
      );
      this.distTopic.subscribe(function(message) {
        _this.distance = message.data;
      });
    },
    //複数地点登録　create
    saveMultiple: function() {
      let multiplePos = this.existsPositions.concat(this.stationMultiplePos);
      this.$apollo
        .mutate({
          mutation: UPDATE_MULTIPLE_POS,
          variables: {
            fieldId: this.field.id,
            input: multiplePos
          }
        })
        .then(result => {
          this.$apollo.queries.multiplePos.refetch();
        })
        .catch(error => {
          console.error(error);
          alert(error);
        });
    },
    //複数地点取得　query
    setExistsPositions: function(pos) {
      this.existsPositions = [];
      this.stationMultiplePos = [];
      this.markers = [];
      for (let val of pos) {
        if (val.node.wayType == ROW_START || val.node.wayType == ROW_END) {
          this.existsPositions.push({
            lat: val.node.lat,
            lon: val.node.lon,
            wayType: val.node.wayType,
            routeNumber: val.node.routeNumber
          });
          this.markers.push({
            position: { lat: val.node.lat, lng: val.node.lon },
            routeNumber: val.node.routeNumber
          });
        } else if (
          val.node.wayType == STATION_START ||
          val.node.wayType == STATION_END
        ) {
          this.stationMultiplePos.push({
            lat: val.node.lat,
            lon: val.node.lon,
            wayType: val.node.wayType,
            routeNumber: val.node.routeNumber
          });
        }
      }
    },
    //複数地点削除
    deleteAll: function() {
      this.$apollo
        .mutate({
          mutation: UPDATE_MULTIPLE_POS,
          variables: {
            fieldId: this.field.id,
            input: this.stationMultiplePos
          }
        })
        .then(result => {
          this.$apollo.queries.multiplePos.refetch();
        })
        .catch(error => {
          console.error(error);
          alert(error);
        });
    },
    deleteAllPosition: function() {
      this.existsPositions = [];
      // this.$refs.gmapObject.deleteMarker();
      this.markers = [];
      this.deleteAll();
    },
    multipleRun: function(targetPointNum) {
      let _this = this;
      this.runClient = this.$refs.rosObject.createActionClient(
        "/legmin_himawari_run_to_multiple_positions/server_run_to_multiple_positions",
        "/legmin_himawari_run_to_multiple_positions/RunToMultiplePositionsAction"
      );
      let goal = new ROSLIB.Goal({
        actionClient: _this.runClient,
        goalMessage: {
          target_point_num: parseInt(targetPointNum),
          field_id: _this.field.id
        }
      });
      goal.on("feedback", function(feedback) {
        console.log("Feedback: ", feedback);
        _this.targetPointNum = feedback.target_point_num;
      });
      goal.on("result", function(result) {
        console.log("Final Result: ", result);
        _this.targetPointNum = feedback.target_point_num;
      });
      goal.send();
    },
    multiplePositionHandlar: function(positions, startPoint) {
      let _this = this;
      let checkVal = 0;
      // Normalize position array
      if (startPoint.split("-")[0] == "bottom") {
        checkVal = 1;
      }
      if (startPoint.split("-")[1] == "right") {
        positions.reverse();
      }
      positions.forEach(function(list, i) {
        if (i % 2 != checkVal) {
          positions[i].reverse();
        }
        list[1].wayType = 2;
      });
      const list = positions.reduce((pre, current) => {
        pre.push(...current);
        return pre;
      }, []);
      this.existsPositions = [];

      for (let i = 0; i < list.length; i++) {
        this.existsPositions.push({
          lat: list[i].lat,
          lon: list[i].lon,
          wayType: list[i].wayType,
          routeNumber: i + 1
        });
      }
      if (this.existsPositions != []) {
        this.saveMultiple();
      } else {
        alert("地点登録エラー");
      }
    },
    operationHandlar: function(targetPointNum) {
      this.multipleRun(targetPointNum);
    }
  },
  beforeDestroy: function() {
    if (this.ros) {
      this.ros.close();
    }
  }
};
</script>
