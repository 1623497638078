<template>
  <b-row>
    <b-col>
      <b-card>
        <b-row>
          <b-col cols="12" lg="12">
            <b-img :src="require('../../assets/image/field.png')" fluid-grow alt="Fluid-grow image"></b-img>
          </b-col>
          <b-col cols="12" lg="6">
            <b-form-group label="・top-left" label-for="top-left">
              <b-form-input
                id="top-left-lat"
                type="text"
                placeholder="lat"
                v-model.number="topLeftLat"
              ></b-form-input>
              <b-form-input
                id="top-left-lon"
                type="text"
                placeholder="lon"
                v-model.number="topLeftLon"
              ></b-form-input>
            </b-form-group>
            <b-button type="submit" size="sm" variant="outline-warning" @click="setPosition(0)">
              <i class="icon-wrench icons"></i> top-left現在地点登録
            </b-button>
          </b-col>
          <b-col cols="12" lg="6">
            <b-form-group label="・top-right" label-for="top-right">
              <b-form-input
                id="top-right-lat"
                type="text"
                placeholder="lat"
                v-model.number="topRightLat"
              ></b-form-input>
              <b-form-input
                id="top-right-lon"
                type="text"
                placeholder="lon"
                v-model.number="topRightLon"
              ></b-form-input>
            </b-form-group>
            <b-button type="submit" size="sm" variant="outline-warning" @click="setPosition(1)">
              <i class="icon-wrench icons"></i> top-right現在地点登録
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" lg="6">
            <b-form-group label="・buttom-left" label-for="rop-left">
              <b-form-input
                id="bottom-left-lat"
                type="text"
                placeholder="lat"
                v-model.number="bottomLeftLat"
              ></b-form-input>
              <b-form-input
                id="bottom-left-lon"
                type="text"
                placeholder="lon"
                v-model.number="bottomLeftLon"
              ></b-form-input>
            </b-form-group>
            <b-button type="submit" size="sm" variant="outline-warning" @click="setPosition(2)">
              <i class="icon-wrench icons"></i> bottom-left現在地点登録
            </b-button>
          </b-col>
          <b-col cols="12" lg="6">
            <b-form-group label="・buttom-right" label-for="top-right">
              <b-form-input
                id="bottom-right-lat"
                type="text"
                placeholder="lat"
                v-model.number="bottomRightLat"
              ></b-form-input>
              <b-form-input
                id="bottom-right-lon"
                type="text"
                placeholder="lon"
                v-model.number="bottomRightLon"
              ></b-form-input>
            </b-form-group>
            <b-button type="submit" size="sm" variant="outline-warning" @click="setPosition(3)">
              <i class="icon-wrench icons"></i> bottom-right現在地点登録
            </b-button>
          </b-col>
          <b-col sm="12">
            <b-form-group label="・横分割数" label-for="width-number">
              <b-form-input
                id="width-number"
                type="text"
                placeholder="横分割数"
                v-model.number="partitionNumberW"
              ></b-form-input>
            </b-form-group>
            <b-form-group>
              <label for="startPoint">・スタート地点</label>
              <b-form-select
                id="startPoint"
                :plain="true"
                :options="['top-left','top-right','bottom-left','bottom-right',]"
                value="top-left"
                v-model="startPoint"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <!-- <b-col>
            <div class="table-responsive">
              <table class="table">
                <td :key="items.id" v-for="items in positions" v-bind="items">
                  <multiple-position-table :key="item.id" v-for="item in items" v-bind="item"></multiple-position-table>
                </td>
              </table>
            </div>
          </b-col>-->
        </b-row>
        <div slot="footer">
          <b-button type="submit" size="sm" variant="primary" @click="calc">
            <i class="icon-plus icons"></i> 複数地点を登録
          </b-button>
          <b-button type="submit" size="sm" variant="warning" @click="fixStartPosition">
            <i class="icon-wrench icons"></i> スタート地点修正
          </b-button>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import MultiplePositionTable from "./MultiplePositionTable";
import { define } from "mime";
export default {
  props: ["lat", "lon"],
  components: {
    MultiplePositionTable
  },
  data() {
    return {
      topLeftLat: 35.092875342,
      topLeftLon: 138.906487623,
      topRightLat: 35.092872302,
      topRightLon: 138.906513157,
      bottomLeftLat: 35.092428487,
      bottomLeftLon: 138.906496083,
      bottomRightLat: 35.092429417,
      bottomRightLon: 138.90652142,
      partitionNumberW: 2, // 横分割数（畝数）
      positions: [],
      startPoint: "top-left",
      sql: null
    };
  },
  methods: {
    calc() {
      let results = new Array();
      let perTopLat = this.calcPerPatition(
        this.topRightLat,
        this.topLeftLat,
        this.partitionNumberW
      );
      let perTopLon = this.calcPerPatition(
        this.topRightLon,
        this.topLeftLon,
        this.partitionNumberW
      );
      let perBottomLat = this.calcPerPatition(
        this.bottomRightLat,
        this.bottomLeftLat,
        this.partitionNumberW
      );
      let perBottomLon = this.calcPerPatition(
        this.bottomRightLon,
        this.bottomLeftLon,
        this.partitionNumberW
      );
      for (let i = 0; i <= this.partitionNumberW; i++) {
        results[i] = new Array();
        let top = {
          lat: this.topLeftLat + perTopLat * i,
          lon: this.topLeftLon + perTopLon * i,
          wayType: 1
        };
        let bottom = {
          lat: this.bottomLeftLat + perBottomLat * i,
          lon: this.bottomLeftLon + perBottomLon * i,
          wayType: 1
        };
        results[i].push(top);
        results[i].push(bottom);
      }
      this.positions = results;
      // console.log(this.positions);

      this.$emit("calcResult", this.positions, this.startPoint);
    },
    setPosition: function(pos) {
      if (pos == 0) {
        this.topLeftLat = this.lat;
        this.topLeftLon = this.lon;
      } else if (pos == 1) {
        this.topRightLat = this.lat;
        this.topRightLon = this.lon;
      } else if (pos == 2) {
        this.bottomLeftLat = this.lat;
        this.bottomLeftLon = this.lon;
      } else if (pos == 3) {
        this.bottomRightLat = this.lat;
        this.bottomRightLon = this.lon;
      }
    },
    calcPerPatition(source, destination, partitionNumber) {
      return (source - destination) / partitionNumber;
    },
    calcAddition(source, destination) {
      return source + destination;
    },
    calcSubtraction(source, destination) {
      return source - destination;
    },
    fixStartPosition() {
      // スタート地点の選択値取得
      let basePointLat;
      let basePointLon;
      let topFlag = 0;
      let rightFlag = 0;
      if (this.startPoint.split("-")[0] == "top") {
        topFlag = 1;
      }
      if (this.startPoint.split("-")[1] == "right") {
        rightFlag = 1;
      }

      if (topFlag == 1 && rightFlag == 1) {
        basePointLat = this.topRightLat;
        basePointLon = this.topRightLon;
      } else if (topFlag == 0 && rightFlag == 1) {
        basePointLat = this.bottomRightLat;
        basePointLon = this.bottomRightLon;
      } else if (topFlag == 0 && rightFlag == 0) {
        basePointLat = this.bottomLeftLat;
        basePointLon = this.bottomLeftLon;
      } else if (topFlag == 1 && rightFlag == 0) {
        basePointLat = this.topLeftLat;
        basePointLon = this.topLeftLon;
      }
      console.log("basePointLanLon: " + basePointLat + "," + basePointLon);

      // 現在地取得
      let nowPosLat = this.lat;
      let nowPosLon = this.lon;
      console.log("現在地点：" + nowPosLat + "," + nowPosLon);

      // 差分計算
      let diffLat = this.calcSubtraction(nowPosLat, basePointLat);
      let diffLon = this.calcSubtraction(nowPosLon, basePointLon);
      console.log("差分：" + diffLat + "," + diffLon);

      // 差分を加算し、現在地を修正
      if (diffLat != null || diffLon != null) {
        this.topLeftLat = this.calcAddition(this.topLeftLat, diffLat);
        this.topLeftLon = this.calcAddition(this.topLeftLon, diffLon);
        this.topRightLat = this.calcAddition(this.topRightLat, diffLat);
        this.topRightLon = this.calcAddition(this.topRightLon, diffLon);
        this.bottomLeftLat = this.calcAddition(this.bottomLeftLat, diffLat);
        this.bottomLeftLon = this.calcAddition(this.bottomLeftLon, diffLon);
        this.bottomRightLat = this.calcAddition(this.bottomRightLat, diffLat);
        this.bottomRightLon = this.calcAddition(this.bottomRightLon, diffLon);
      }
    }
  }
};
</script>
