<template>
  <b-card>
    <b-row>
      <b-col cols="12" lg="12">
        <b-button block variant="primary" @click="multipleRun">自動走行開始</b-button>
      </b-col>
    </b-row>
    <br />
    <b-row>
      <b-col cols="12" lg="12">
        <b-form-group label-for="index" :label-cols="0">
          <b-input-group>
            <b-form-input
              id="index"
              type="text"
              placeholder="自動走行を再開する地点の番号"
              v-model.number="targetPointNum"
              autocomplete="index"
            ></b-form-input>
            <b-input-group-prepend>
              <b-button block variant="warning" @click="multipleReturn">自動走行再開</b-button>
            </b-input-group-prepend>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" lg="12">
        <b-button block variant="danger" @click="multipleStop">
          <i class="fa fa-ban"></i> 自動走行停止
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  props: ["targetPointNum"],
  methods: {
    multipleStop() {
      this.$emit("multipleStop", 0);
    },
    multipleRun() {
      this.$emit("multipleRun", 1);
    },
    multipleReturn() {
      this.$emit("multipleReturn", this.targetPointNum);
    }
  }
};
</script>
